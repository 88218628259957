<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>
        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
        <h3>
          <div class="row">
            <div class="col-md-10 col-12">
              <b>Daftar Surat Masuk Pimpinan</b>
            </div>
            <div class="col-md-2 col-12" v-if="canSeeAll">
              <select
                class="pull-right form-control"
                v-model="from"
                @change="getFrom"
              >
                <option value="me">Diri Sendiri</option>
                <option value="all">Satu Unit</option>
              </select>
            </div>
          </div>
        </h3>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <Table
              :items="items"
              :filter="filters"
              :header="headers"
              :onAction="get"
              :onDetail="doDetail"
              :onEdit="doEdit"
              :onKonfirmasi="doKonfirmasi"
              :onDisposisi="doDisposisi"
              @onDelete="doDelete"
              :overideSearch="search"
              @onSearch="onSearch"
              :forbidEdit="forbidUpdate"
              :forbidDetail="forbidRead"
              :forbidKonfirmasi="forbidKonfirmasi"
              :forbidDisposisi="forbidDisposisi"
              :forbidDelete="true"
            />
          </div>
        </div>
        <hr />
      </div>
      <hr />
      <div class="card card-body printableArea">
        <div class="col-md-12">
          <h4>Petunjuk :</h4>
        </div>
        <div class="col-md-12">
          <table>
            <tr>
              <td>
                <span class="btn-sm btn-success mr-1">
                  <i class="mdi mdi-magnify"></i>
                </span>
              </td>
              <td style="font-size: 13px">
                Untuk melihat data detail surat masuk
              </td>
            </tr>
            <tr>
              <td colspan="2">&nbsp;</td>
            </tr>
            <tr>
              <td>
                <span class="btn-sm btn-warning mr-1"
                  ><i class="mdi mdi-grease-pencil"></i
                ></span>
              </td>
              <td style="font-size: 13px">Untuk mengedit surat masuk</td>
            </tr>
            <tr>
              <td colspan="2">&nbsp;</td>
            </tr>
            <tr>
              <td>
                <span class="btn-sm btn-info mr-1"
                  ><i class="mdi mdi-check"></i
                ></span>
              </td>
              <td style="font-size: 13px">Untuk mengkonfirmasi surat masuk</td>
            </tr>
            <tr>
              <td colspan="2">&nbsp;</td>
            </tr>
            <tr>
              <td>
                <span class="btn-sm btn-secondary mr-1"
                  ><i class="mdi mdi-account"></i
                ></span>
              </td>
              <td style="font-size: 13px">
                Untuk mendisposisi surat masuk, dengan cara harus mengkonfirmasi
                surat masuk terlebih dahulu
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from "@/components/table/table";
import { RotateSquare5 } from "vue-loading-spinner";

export default {
  components: {
    RotateSquare5,
    Table,
  },
  data() {
    let last = localStorage.getItem("last_request");
    last = last != null ? last.split("?") : ",?,".split("?");
    let params = {};
    if (last[0].includes("document_in_supervisor")) {
      params = JSON.parse(last[1]);
    }
    if (params.approval != undefined) {
      params = {};
    }
    return {
      from: "all",
      search: params.search || "",
      headers: {
        subject: {
          label: "Perihal2",
          sortable: true,
        },
        agenda_number: {
          label: "Nomor Agenda",
          sortable: true,
        },
        nomor_surat: {
          label: "Nomor Surat",
          sortable: true,
        },
        asal_surat: {
          label: "Asal Surat",
          sortable: true,
        },
        sifat: {
          label: "Sifat Surat",
          sortable: true,
        },
        tanggal_diterima: {
          label: "Tanggal Diterima",
          sortable: true,
          type: "date",
        },
        redaksi: {
          label: "Redaksi",
          sortable: true,
        },
        profil: {
          label: "Profil",
          sortable: false,
        },
        status: {
          label: "Status",
        },
      },
    };
  },
  computed: {
    items() {
      let data = [];
      if (this.$store.state.documentInSupervisor.items !== undefined) {
        if (this.$store.state.documentInSupervisor.items.items !== undefined) {
          if (
            !Array.isArray(
              this.$store.state.documentInSupervisor.items.items
            ) &&
            this.$store.state.documentInSupervisor.items.items !== undefined
          ) {
            data = Object.keys(
              this.$store.state.documentInSupervisor.items.items
            ).map((i) => this.$store.state.documentInSupervisor.items.items[i]);
          } else {
            data = this.$store.state.documentInSupervisor.items.items;
          }
        }
      }
      return data;
    },
    filters() {
      let filt = {};
      if (this.$store.state.documentInSupervisor.items !== undefined) {
        if (
          this.$store.state.documentInSupervisor.items.filters !== undefined
        ) {
          filt = this.$store.state.documentInSupervisor.items.filters;
          // filt = (this.$store.state.documentInSupervisor.items.filters).then(result=>{
          //   const state = {
          //     loaded: true
          //   };
          //   this.isVisible = true;
          // }).catch(err=>{
          //   const state = {
          //     loaded: true
          //   };
          //   this.isVisible = false;
          // });
        }
      }
      return filt;
    },
    state() {
      return this.$store.state.documentInSupervisor;
    },
    forbidUpdate() {
      if (this.$store.state.profile.permissions.document_in_supervisor) {
        return !this.$store.state.profile.permissions.document_in_supervisor
          .update;
      }
      return false;
    },
    canSeeAll() {
      if (this.$store.state.profile.permissions.document_in_supervisor_all) {
        return this.$store.state.profile.permissions.document_in_supervisor_all
          .read;
      }
      return false;
    },
    forbidRead() {
      if (this.$store.state.profile.permissions.document_in_supervisor) {
        return !this.$store.state.profile.permissions.document_in_supervisor
          .read;
      }
      return false;
    },
    forbidKonfirmasi() {
      if (
        this.$store.state.profile.permissions.document_in_supervisor_confirm
      ) {
        return this.$store.state.profile.permissions
          .document_in_supervisor_confirm.create;
      }
      return false;
    },
    forbidDel() {
      if (this.$store.state.profile.permissions.document_in_supervisor) {
        return !this.$store.state.profile.permissions.document_in_supervisor
          .delete;
      }
      return false;
    },
    forbidDisposisi() {
      if (this.$store.state.profile.permissions.disposition_in) {
        return this.$store.state.profile.permissions.disposition_in.create;
      }
      return false;
    },
  },
  mounted() {
    const state = {
      loaded: false,
      items: [],
      search: this.search,
      from: this.from,
    };
    this.$store.commit("documentInSupervisor/STATE", state);
    this.get(state);
  },
  methods: {
    onSearch(val) {
      this.search = val;
    },
    get(val) {
      this.$store.dispatch("documentInSupervisor/getDocumentInSupervisor", val);
    },
    doDetail(val) {
      this.$store.dispatch("documentInSupervisor/onDetail", val);
    },
    doEdit(val) {
      this.$store.dispatch("documentInSupervisor/onEdit", val);
    },
    doKonfirmasi(val) {
      this.$store.dispatch("documentInSupervisor/onKonfirmasi", val);
    },
    // doKonfirmasi(val) {
    //   this.$store.dispatch("documentInSupervisor/onConfirm", val);
    // },
    doDisposisi(val) {
      this.$store.dispatch("documentInSupervisor/onDisposisi", val);
    },
    doDelete(val) {
      this.$store.dispatch("documentInSupervisor/submitDelete", val);
    },
    getFrom() {
      const state = {
        search: this.search,
        from: this.from,
      };
      this.$store.commit("documentInSupervisor/STATE", state);
      this.get(state);
    },
  },
};
</script>
